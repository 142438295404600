const noValuePlaceholder = "N/A";

const stringifyInteger = (number, prefix = "") => {
  if (typeof number !== "number") {
    return noValuePlaceholder;
  }

  const withThousandsDelimiters = number.toLocaleString("en-US");
  const isNegative = number < 0;

  if (isNegative) {
    // Move the negative sign to before the prefix
    // eg: `-$133,580`
    return `-${prefix}${withThousandsDelimiters.replace(/^-/, "")}`;
  } else {
    return `${prefix}${withThousandsDelimiters}`;
  }
};

const formatNtee = (ntee, description) => {
  // See no-data values discussed here:
  // https://app.asana.com/0/0/1203026964340229/1203031364426279/f
  const noDataValueNtee = "UNKNOWN";
  const noDataValueDescription = "Unknown";

  if (ntee === noDataValueNtee) {
    return noValuePlaceholder;
  } else if (!description || description === noDataValueDescription) {
    return `${ntee}`;
  } else {
    return `${ntee} - ${description}`;
  }
};

const getCleanedWebsite = (website) => {
  // check for null and email addresses provided as a website
  if (!website || website.includes("@") || !website.includes(".")) {
    return null;
  }

  let cleanedWebsite = website.toLowerCase().replace(/ /g, "");

  // set protocol if none present
  const hasProtocol =
    cleanedWebsite.startsWith("http://") ||
    cleanedWebsite.startsWith("https://");

  cleanedWebsite = hasProtocol ? cleanedWebsite : "http://" + cleanedWebsite;

  // check for multiple website domains, select the
  // first pair if more than 1 found
  let split = cleanedWebsite.split("www");

  if (split.length > 2) {
    cleanedWebsite = split[0] + "www" + split[1];
  }

  return cleanedWebsite;
};

module.exports = {
  stringifyInteger,
  formatNtee,
  noValuePlaceholder,
  getCleanedWebsite,
};
