import React from "react";
import { sortBy } from "lodash";
import { Table as RsuiteTable, Column, HeaderCell, Cell } from "rsuite-table";
import { stringifyInteger, noValuePlaceholder } from "../utils";
import ExternalLinkIcon from "@rsuite/icons/legacy/ExternalLink";
import "rsuite-table/dist/css/rsuite-table.min.css";

/**
 *   This all-purpose Table component is an additional abstraction layer that
 *   facilitates the rendering of tabular data and should eliminate the need
 *   to repeat tabular code thus keeping our codebase DRY.
 *
 *   NOTE: this table uses RSuite Table under the hood and thus to take
 *   advantage of certain RSuite table properties such as `autoHeight` and
 *   `rowHeight`, they must be passed via a `rsuiteTableProps` object prop.
 *   It's important to note that only Rsuite Table properties that are of the
 *   `boolean`, `string`, `number/integer` data types must be passed via the
 *   `rsuiteTableProps` prop AND any other event-driven Rsuite table properties
 *    such as onRowClick() should be defined on the topmost level (i.e. `data`).
 *
 *    RSuite Table Documentation: https://rsuitejs.com/components/table/
 *
 *    Table also expects a columnConfig list with objects defining WHAT and HOW
 *    data attributes should be rendered. To make certain columns FIXED and/or
 *    SORTABLE, please define the `sortable` and `fixed` boolean attributes on
 *    desired columnConfig objects.
 *
 *    Furthermore, to take full advantage of other Rsuite Table properties, they
 *    will have to be defined/coded into the Table logic below.
 */
class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortColumn: null,
      // For whatever reason, the library wants this
      // value initialized to `desc` instead of `null`
      sortType: "desc",
    };
  }

  render() {
    // RSuite Table works best when all of the data is
    // in clean columns ready for display. That is, we
    // need to execute any of our text cleaning, digit
    // formatting, and concatenation _before_ the data
    // gets handed to RSuite. This code block executes
    // the `columnConfig`'s `value` functions for each
    // row of the `this.props.data`.
    // Given that EIN is unique and needs to be known
    // in order to display the correct nonprofit data
    // on the modal, we add it in Object.assign -
    // note: the EIN won't be displayed on the UI.
    const displayData = this.props.data.map((d) =>
      this.props.columnConfig.reduce(
        (accumulator, currentColumn) =>
          Object.assign(
            // the enrich callback function adds additional attributes that
            // will be hidden and never shown on the table. enrich() must
            // exist as a property on the item in order to be invoked.
            d.enrich ? d.enrich(d) : { ein: d.ein },
            accumulator,
            {
              [currentColumn.header]: currentColumn.value(d),
            }
          ),
        {}
      )
    );

    // Sort the data, if necessary
    let sortedData = displayData;
    if (this.state.sortColumn && this.state.sortType) {
      sortedData = sortBy(sortedData, [this.state.sortColumn]);
      if (this.state.sortType === "desc") {
        sortedData = sortedData.reverse();
      }

      // Always put `null` values at the bottom, since
      // these aren't of much use to the user
      sortedData = sortedData
        .filter((i) => i[this.state.sortColumn] !== null)
        .concat(sortedData.filter((i) => i[this.state.sortColumn] === null));
    }

    return (
      <RsuiteTable
        className={`${this.props.tableClasses ? this.props.tableClasses : ""}`}
        width={this.props.rsuiteTableProps.width}
        onRowClick={(rowData, e) => {
          rowData.hasData || rowData.ein
            ? this.props.onRowClick(rowData.ein)
            : e.preventDefault();
        }}
        data={sortedData}
        // These have to be set here, rather than in CSS
        headerHeight={
          this.props.rsuiteTableProps.headerHeight
            ? this.props.rsuiteTableProps.headerHeight
            : 35
        }
        rowHeight={
          this.props.rsuiteTableProps.rowHeight
            ? this.props.rsuiteTableProps.rowHeight
            : 30
        }
        autoHeight={Boolean(this.props.rsuiteTableProps.autoHeight)}
        fillHeight={Boolean(this.props.rsuiteTableProps.fillHeight)}
        cellBordered={Boolean(this.props.rsuiteTableProps.cellBordered)}
        // Unfortunately `wordWrap` and `virtualized` do not
        // work well together on very large tables, so we'll
        // need to deal with truncated cell text sometimes
        virtualized={Boolean(this.props.rsuiteTableProps.virtualized)}
        onSortColumn={(sortColumn, sortType) => {
          this.setState({ sortColumn, sortType });
        }}
        sortColumn={this.state.sortColumn}
        sortType={this.state.sortType}
        rowClassName={(rowData) => {
          if (this.props.rsuiteTableProps?.customRowHeaders)
            return CustomRowStyling(
              rowData,
              this.props.rsuiteTableProps.customRowHeaders.colName,
              this.props.rsuiteTableProps.customRowHeaders.rows
            );
        }}
      >
        {this.props.columnConfig.map((column) => (
          <Column
            width={column.width}
            align={
              column.cellType === "number"
                ? "right"
                : column.cellType === "linkIcon"
                ? "center"
                : "left"
            }
            // Noting since the documentation is unclear: a
            // `fixed` column in RSuite is the same thing as
            // a "frozen" column/row in Excel; it will always
            // remain on screen as the user scrolls the table
            fixed={column.fixed}
            key={column.header}
            resizable
            sortable={column.sortable}
          >
            {column.headerLink ? (
              <HeaderCell className="font-semibold text-sm leading-tight">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={column.headerLink}
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  {column.header}
                </a>
              </HeaderCell>
            ) : (
              <HeaderCell className="font-semibold text-sm leading-tight">
                {column.header}
              </HeaderCell>
            )}
            {column.cellType === "linkIcon" ? (
              <LinkIconCell dataKey={column.header} />
            ) : column.cellType === "number" ? (
              <IntegerCell
                dataKey={column.header}
                className={
                  column.classes
                    ? column.classes
                    : "text-xs leading-3 !bg-inherit"
                }
              />
            ) : column.cellType === "attribute_category" ||
              column.cellType === "name" ? (
              <NameTextCell
                dataKey={column.header}
                className={
                  column.classes ? column.classes : "text-xs leading-3"
                }
                defaultStyling={column.defaultStyling}
              />
            ) : (
              <TextCell
                dataKey={column.header}
                className={
                  column.classes ? column.classes : "text-xs leading-3"
                }
              />
            )}
          </Column>
        ))}
      </RsuiteTable>
    );
  }
}

function LinkIconCell({ rowData, dataKey, ...props }) {
  const hasUrl = Boolean(rowData[dataKey]);

  if (hasUrl) {
    return (
      <Cell dataKey={dataKey} {...props}>
        <a target="_blank" rel="noreferrer" href={rowData[dataKey]}>
          {/*
          Use `transform` to position the icon properly within
          the cell
        */}
          <ExternalLinkIcon
            style={{ fontSize: "0.82rem", transform: "translate(0, -0.43rem)" }}
          />
        </a>
      </Cell>
    );
  } else {
    return (
      <Cell className="text-xs leading-tight" {...props}>
        {noValuePlaceholder}
      </Cell>
    );
  }
}

const IntegerCell = ({ rowData, dataKey, ...props }) => (
  <Cell dataKey={dataKey} className="text-xs leading-tight" {...props}>
    {/* customize styling for financial data */}
    {typeof rowData[dataKey] === "number"
      ? stringifyInteger(rowData[dataKey]) && rowData.isFinanceData
        ? `$${stringifyInteger(rowData[dataKey])}`
        : stringifyInteger(rowData[dataKey])
      : noValuePlaceholder}
  </Cell>
);

const TextCell = ({ rowData, dataKey, ...props }) => (
  <Cell dataKey={dataKey} className="text-xs leading-tight" {...props}>
    {rowData[dataKey] || noValuePlaceholder}
  </Cell>
);

// Create a separate type of text cell for the first/name
// column cells. This doesn't allow placeholder text, and
// has extra styling.
const NameTextCell = ({ rowData, dataKey, defaultStyling = [], ...props }) => (
  <Cell dataKey={dataKey} className="text-xs leading-tight" {...props}>
    {/*
  Fake an anchor tag, since there's no `href` to be
  passed

  Account for the following cases:
      1. organization data doesn't exist so it shouldn't be/look clickable
      2. within funder modal tables, only apply styling to grantee rows
  */}
    {!defaultStyling.includes(rowData[dataKey]) &&
    !rowData.hasData &&
    rowData.isFunder ? (
      <span className="text-xs leading-3">{rowData[dataKey]}</span>
    ) : !defaultStyling.includes(rowData[dataKey]) ? (
      <span className="cursor-pointer underline text-blue-600 hover:text-blue-800">
        {rowData[dataKey]}
      </span>
    ) : (
      <span className="text-xs leading-3">{rowData[dataKey]}</span>
    )}
  </Cell>
);

const CustomRowStyling = (rowData, colName, staticRowValues) => {
  return rowData &&
    rowData[colName] &&
    staticRowValues.includes(rowData[colName])
    ? "bg-neutral-100"
    : "";
};

export { Table, IntegerCell, TextCell, NameTextCell };
