import React from "react";
import { Modal } from "./Modal";
import { Table } from "./Table";
import "rsuite-table/lib/less/index.less"; // or 'rsuite-table/dist/css/rsuite-table.css'
import { noValuePlaceholder, getCleanedWebsite, formatNtee } from "../utils";
import { InfoIcon } from "./Info";
import { startCase, sum } from "lodash";

// FunderRowHeaders is used to facilitate custom styling within the bounds of RsuiteTable
const FunderRowHeaders = [
  "Total Community Nonprofits Funded (#)",
  "Total Community Nonprofits Funded ($)",
];

function EntityDetailModal(props) {
  return (
    <Modal
      title={props.title}
      content={props.content}
      onClose={props.onClose}
    />
  );
}

const NonprofitEntityDetailModal = (props) => {
  const isFunder = Boolean(props.funderRecord);
  const isNonprofit = Boolean(props.nonprofitRecord);
  const isNonprofitFunder = isFunder && isNonprofit;

  return (
    <Modal
      title={props.nonprofitRecord.name}
      onClose={() => {
        props.setModalEin(null);
      }}
      content={
        <div>
          <FullAddress
            street={props.nonprofitRecord.street}
            city={props.nonprofitRecord.city}
            state={props.nonprofitRecord.state}
            zip={props.nonprofitRecord.zip5}
          />
          <IssueAreas data={props.nonprofitRecord.org_classes} />
          <NteeCode record={props.nonprofitRecord} />
          <WebsiteUrl url={props.nonprofitRecord.website} />

          <span className="my-3 block">
            <b>Mission</b>:{" "}
            {props.nonprofitRecord.mission
              ? props.nonprofitRecord.mission
              : noValuePlaceholder}
          </span>
          <ReturnType value={props.nonprofitRecord.return_type} />

          {/* More Entity/Org Details */}
          <NonprofitDetailsTable {...props.nonprofitRecord} />

          {/* If Nonprofit Funder, show Community Funding Details below */}
          {isNonprofitFunder && <FunderFundingDetailsTable {...props} />}
        </div>
      }
    />
  );
};

const FunderEntityDetailModal = (props) => {
  return (
    <Modal
      title={props.funderRecord.funder_name}
      onClose={() => {
        props.setModalEin(null);
      }}
      content={
        <div>
          <FullAddress
            street={props.funderRecord.funder_street}
            city={props.funderRecord.funder_city}
            state={props.funderRecord.funder_state}
            zip={props.funderRecord.funder_zip5}
          />

          {/* Community Funding Details below */}
          <FunderFundingDetailsTable {...props} />
        </div>
      }
    />
  );
};

const ReturnType = ({ value }) => (
  <span className="my-3 block">
    <b>Return Type</b>: {value ? value : noValuePlaceholder}
  </span>
);

/**
 * @param data - an array of issue areas (i.e. ['education', 'immigration'])
 */
const IssueAreas = ({ data }) => (
  <span className="my-3 block">
    <b>Topics</b>:{" "}
    {data && data.length > 0
      ? data.map((i) => startCase(i)).join(", ")
      : noValuePlaceholder}
  </span>
);

/**
 * @param url -- the organization's website URL (i.e. www.communitypartnershipfinder.org)
 */
const WebsiteUrl = ({ url }) => {
  return (
    <span className="my-3 block">
      <b>Website</b>:{" "}
      {url ? (
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          rel="noreferrer"
          href={getCleanedWebsite(url)}
          target="_blank"
        >
          {getCleanedWebsite(url)}
        </a>
      ) : (
        noValuePlaceholder
      )}
    </span>
  );
};

/**
 * @param {string} street
 * @param {string} city
 * @param {string} state
 * @param {string | number } zip
 */
const FullAddress = ({ street, city, state, zip }) => (
  <address className="my-3 not-italic">
    {street ? street : ""} <br />
    {city ? city : ""}, {state ? state : ""} {zip ? zip : ""}
  </address>
);

/**
 * @param record - an entity containing a readable Ntee code (i.e. nonprofitRecord)
 * @param record.ntee - the main ntee code of the entity
 * @param record.description3 - the description of the ntee code
 */
const NteeCode = ({ record }) => (
  <span className="my-3 block">
    <a
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      target="_blank"
      rel="noreferrer"
      href="https://nccs.urban.org/project/national-taxonomy-exempt-entities-ntee-codes"
    >
      <b>
        <u>NTEE Code</u>
      </b>
    </a>
    : {formatNtee(record.ntee, record.description3)}
  </span>
);

class NonprofitDetailsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      containerWidth: 0,
    };

    this.selfRef = React.createRef();
  }

  componentDidMount() {
    // Mount initially without a table to help determine what
    // `width` prop value should be passed to the `RSuiteTable`
    // component
    if (!this.state.containerWidth) {
      // Need to subtract a small amount to avoid remaining
      // width overlow issues on some browsers
      const containerWidth = this.selfRef.current.clientWidth - 2;
      this.setState({ containerWidth });
    }
  }

  render() {
    const attributesToDisplay = {
      revenue_timeline: "Revenue", // Array
      employee_timeline: "Count Employees", // Array
      volunteer_timeline: "Count Volunteers", // Array
    };
    const columnsConfig = [
      {
        cellType: "attribute_category",
        header: "Year",
        value: (row) => row.attribute_category,
        width: 160,
        classes: "font-bold text-xs leading-3",
        fixed: true,
        defaultStyling: [
          "Revenue",
          "Count Employees",
          "Count Volunteers",
        ].concat(FunderRowHeaders),
      },
      {
        cellType: "number",
        header: "2018",
        value: (row) => row["2018"],
        width: 150,
        classes: null,
      },
      {
        cellType: "number",
        header: "2019",
        value: (row) => row["2019"],
        width: 150,
        classes: null,
      },
      {
        cellType: "number",
        header: "2020",
        value: (row) => row["2020"],
        width: 150,
        classes: null,
      },
      {
        cellType: "number",
        header: "2021",
        value: (row) => row["2021"],
        width: 150,
        classes: null,
      },
    ];

    const data = [];
    /**
     *   The following lines of code ensure data is in a structure that's easily
     *    readable by Rsuite Table and should look like:
     *
     *   [  { attribute_category: "Revenue",      2018: 34456, ...,   2021: 2225 },
     *      { attribute_category: "Count Employees",  2018: 56, ...,    2021: 89 }  ]
     */
    for (const [field, value] of Object.entries(attributesToDisplay)) {
      if (this.props[field]) {
        // i.e. return_type, employee_timeline, etc.
        const entry = {
          attribute_category: value,
        };
        data.push(Object.assign(entry, this.props[field]));
      }
    }

    // enrichData() adds necessary fields/attributes to inform components such as
    // IntegerCell how to format financial data (as an example). These attributes
    // are hidden and never rendered on the table UI.
    const enrichData = (data) => {
      return data.map((rowData) => {
        return Object.assign(rowData, {
          enrich: (item) => ({
            isFinanceData:
              "Revenue" === item["attribute_category"] ? true : false,
          }),
        });
      });
    };
    const dataToDisplay = enrichData(data);

    const sumColumnsWidth = sum(columnsConfig.map((i) => i.width));

    if (!this.state.containerWidth) {
      // First, mount without the `RSuiteTable` to determine
      // the available width of the container
      return <div className="w-full" ref={this.selfRef}></div>;
    } else {
      const tableWidth = Math.min(this.state.containerWidth, sumColumnsWidth);

      return (
        <div className="my-3 border border-black inline-flex">
          <Table
            rsuiteTableProps={{
              width: tableWidth,
              autoHeight: true,
              cellBordered: true,
              customRowHeaders: { rows: FunderRowHeaders, colName: "Year" },
            }}
            columnConfig={columnsConfig}
            data={dataToDisplay}
          />
        </div>
      );
    }
  }
}

/**
 *
 * @param props - funderRecord
 */
class FunderFundingDetailsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      containerWidth: 0,
    };

    this.selfRef = React.createRef();
  }

  componentDidMount() {
    // Mount initially without a table to help determine what
    // `width` prop value should be passed to the `RSuiteTable`
    // component
    if (!this.state.containerWidth) {
      // Need to subtract a small amount to avoid remaining
      // width overlow issues on some browsers
      const containerWidth = this.selfRef.current.clientWidth - 2;
      this.setState({ containerWidth });
    }
  }

  render() {
    const funderRecordAttributesToDisplay = {
      yearly_grant_totals: FunderRowHeaders[1], // Array
    };
    const columnsConfig = [
      {
        cellType: "attribute_category",
        header: "Year",
        value: (row) => row.attribute_category,
        width: 280,
        classes: "font-bold text-xs leading-3 !bg-inherit",
        fixed: true,
        defaultStyling: [
          "Revenue",
          "Count Employees",
          "Count Volunteers",
        ].concat(FunderRowHeaders),
      },
      {
        cellType: "number",
        header: "2018",
        value: (row) => row["2018"],
        width: 120,
        classes: null,
      },
      {
        cellType: "number",
        header: "2019",
        value: (row) => row["2019"],
        width: 120,
        classes: null,
      },
      {
        cellType: "number",
        header: "2020",
        value: (row) => row["2020"],
        width: 120,
        classes: null,
      },
      {
        cellType: "number",
        header: "2021",
        value: (row) => row["2021"],
        width: 120,
        classes: null,
      },
    ];

    const data = [];
    /**
     *   The following lines of code ensure data is in a structure that's easily
     *    readable by Rsuite Table and should look like:
     *
     *   [ { attribute_category: "Nonprofit Named X",  2018: $10,00, ..., 2022: $0 } ]
     */
    data.push(
      Object.assign(
        { attribute_category: FunderRowHeaders[0] },
        this.props.uniqueGrantsMadePerYear
      )
    );

    for (const [field, value] of Object.entries(
      funderRecordAttributesToDisplay
    )) {
      if (this.props.funderRecord[field]) {
        // i.e. ein, funder_city, etc.
        data.push(
          Object.assign(
            { attribute_category: value },
            this.props.funderRecord[field]
          )
        );
      }
    }

    for (const [field, value] of Object.entries(
      this.props.granteeFundingPerYear
    )) {
      if (this.props.granteeFundingPerYear[field]) {
        // i.e. orgNameOnGrant, fundingPerYear, etc.
        const entry = { attribute_category: value["orgNameOnGrant"] };
        data.push(
          Object.assign(
            entry,
            this.props.granteeFundingPerYear[field]["fundingPerYear"]
          )
        );
      }
    }

    const getEin = (rowData) => {
      for (const [ein, data] of Object.entries(
        this.props.granteeFundingPerYear
      )) {
        if (rowData.attribute_category === data.orgNameOnGrant) {
          return Number(ein);
        }
      }
    };

    const getStatus = (rowData) => {
      const grantees = this.props.granteeFundingPerYear;
      for (const ein in grantees) {
        if (rowData.attribute_category === grantees[ein].orgNameOnGrant) {
          return grantees[ein].hasData;
        }
      }
    };

    // enrichData() adds necessary fields/attributes to inform components such as
    // IntegerCell how to format financial data (as an example). These attributes
    // are hidden and never rendered on the table UI.
    const enrichData = (data) => {
      return data.map((rowData) => {
        return Object.assign(rowData, {
          enrich: (rowData) => ({
            ein: getEin(rowData),
            hasData: getStatus(rowData),
            isFunder: true,
            isFinanceData:
              FunderRowHeaders[0] === rowData["attribute_category"]
                ? false
                : true,
          }),
        });
      });
    };

    // This steps adds an "enrich" callback function whose purpose is to add
    // additional hidden attributes.
    const dataToDisplay = enrichData(data);

    const sumColumnsWidth = sum(columnsConfig.map((i) => i.width));

    if (!this.state.containerWidth) {
      // First, mount without the `RSuiteTable` to determine
      // the available width of the container
      return <div className="w-full" ref={this.selfRef}></div>;
    } else {
      const tableWidth = Math.min(this.state.containerWidth, sumColumnsWidth);

      return (
        <div className="mt-6">
          <h2 className="text-2xl">
            Community Funding Details{" "}
            <InfoIcon
              elementName="Community Funding Details"
              content={
                <React.Fragment>
                  <p>
                    Grant data is sourced from Form 990 Schedule I and Form
                    990PF Part XV by selecting all grants provided to the
                    selected geography based on the address provided by the
                    funder for the recipient. Grants are matched to a tax-exempt
                    organization using the{" "}
                    <a
                      className="underline
                    text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      href="https://www.irs.gov/charities-non-profits/employer-identification-number"
                      target="_blank"
                      rel="noreferrer"
                    >
                      IRS employer identification number (EIN)
                    </a>{" "}
                    in the Form 990, or by algorithmically matching based on
                    organization name and address when the EIN is not provided
                    in the Form 990PF. When matching the 990PF grants
                    algorithmically, if no reasonable match was found that data
                    was removed. If the tax-exempt organization's EIN is located
                    in the selected geography, a hyperlink is provided to their
                    available data on this platform. If the grant contribution
                    value is equal to zero in the table, this indicates that the
                    funder provided other forms of assistance than direct cash
                    to the organization.
                  </p>
                </React.Fragment>
              }
            />
          </h2>
          <div className="my-3 border border-black inline-flex">
            <Table
              rsuiteTableProps={{
                width: tableWidth,
                autoHeight: true,
                cellBordered: true,
                customRowHeaders: { rows: FunderRowHeaders, colName: "Year" },
              }}
              columnConfig={columnsConfig}
              data={dataToDisplay}
              onRowClick={this.props.setModalEin}
            />
          </div>
        </div>
      );
    }
  }
}

export {
  EntityDetailModal,
  NonprofitEntityDetailModal,
  FunderEntityDetailModal,
};
