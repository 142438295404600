import React from "react";
import { InfoModal, InfoModalContext } from "./components/Info";
import { featureCollection } from "@turf/helpers";
import { getCleanedWebsite } from "./utils";
import Filters from "./components/Filters";
import MapPane from "./components/MapPane";
import { NonprofitTablePane, FunderTablePane } from "./components/TablePane";
import {
  NonprofitEntityDetailModal,
  FunderEntityDetailModal,
} from "./components/EntityDetailModal";
import GeographySelectModal from "./components/GeographySelectModal";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    // Use this state when a geography hasn't yet been
    // selected, or the user is in the process of changing
    // the geography
    this.nullGeographyState = {
      geography: null,
      funderData: [],
      nonprofitData: [],
      grantData: [],
    };

    this.state = Object.assign({}, this.nullGeographyState, {
      // The EIN of the entity being displayed in the modal
      modalEin: null,
      taxYear: 2021,
      issueAreas: [],
    });

    this.switchGeography = this.switchGeography.bind(this);
    this.unsetGeography = this.unsetGeography.bind(this);
    this.getEntityModalData = this.getEntityModalData.bind(this);
    this.getNonprofitData = this.getNonprofitData.bind(this);
    this.getFunderData = this.getFunderData.bind(this);
    this.getFunderRecord = this.getFunderRecord.bind(this);
    this.getNonprofitRecord = this.getNonprofitRecord.bind(this);
    this.setTaxYear = this.setTaxYear.bind(this);
    this.setIssueAreas = this.setIssueAreas.bind(this);
    this.setModalEin = this.setModalEin.bind(this);
  }

  // This method will eventually be hooked into the geography-select
  // modal
  async switchGeography(geography) {
    // All of thse JSON files are loaded in the browser only as-needed,
    // so as to not increase the page load times; see the note in the
    // `data` directory's README file
    let funderData;
    let nonprofitData;
    let grantData;
    let indexData;

    if (geography === "dc") {
      funderData = Array.from(await import("./data/dc/funders.json"));
      nonprofitData = Array.from(await import("./data/dc/nonprofits.json"));
      grantData = Array.from(await import("./data/dc/grants.json"));
      // The JSON loader built into Webpack won't function
      // unless these files have a `.json` extension instead
      // of a `.geojson` extension. Further, it's tough to
      // convert a `module` into an `object`, so we'll just
      // extract the `features` and insert them into a new
      // `FeatureCollection`.
      indexData = featureCollection(
        (await import("./data/dc/index.json")).features
      );
    } else if (geography === "epa") {
      funderData = Array.from(await import("./data/epa/funders.json"));
      nonprofitData = Array.from(await import("./data/epa/nonprofits.json"));
      grantData = Array.from(await import("./data/epa/grants.json"));
      indexData = featureCollection(
        (await import("./data/epa/index.json")).features
      );
    } else if (geography === "nola") {
      funderData = Array.from(await import("./data/nola/funders.json"));
      nonprofitData = Array.from(await import("./data/nola/nonprofits.json"));
      grantData = Array.from(await import("./data/nola/grants.json"));
      indexData = featureCollection(
        (await import("./data/nola/index.json")).features
      );
    }

    this.setState({
      geography,
      funderData,
      nonprofitData,
      grantData,
      indexData,
    });
  }

  // For development, uncomment this method in order to
  // bypass the area-select modal landing page and jump
  // straight to the interface, with DC data loaded
  // componentDidMount() {
  //   this.switchGeography("dc");
  // }

  unsetGeography() {
    this.setState(this.nullGeographyState);
  }

  getFunderRecord = (ein) => this.state.funderData.find((i) => i.ein === ein);

  getNonprofitRecord = (ein) =>
    this.state.nonprofitData.find((i) => i.ein === ein);

  getEntityModalData() {
    let entityModalData = {};

    if (this.state.modalEin) {
      // Fetch all of the information available about the entity,
      // based on their EIN
      const funderRecord = this.getFunderRecord(this.state.modalEin);
      const nonprofitRecord = this.getNonprofitRecord(this.state.modalEin);
      const grantsMade = this.state.grantData.filter(
        (i) => i.funder_ein === this.state.modalEin
      );
      const grantsReceived = this.state.grantData.filter(
        (i) => i.grantee_ein === this.state.modalEin
      );

      // extract the data we need from grantsMade
      let uniqueGranteesPerYear = {};
      let uniqueGrantsMadePerYear = {};
      let granteeFundingPerYear = {};
      grantsMade.forEach((i) => {
        const taxYear = i.tax_year;
        const granteeEin = i.grantee_ein;
        const grantAmount = i.grant_amount;

        // capture unique grantees per year
        if (uniqueGranteesPerYear[taxYear] !== undefined) {
          uniqueGranteesPerYear[taxYear].add(granteeEin);
        } else {
          uniqueGranteesPerYear[taxYear] = new Set().add(granteeEin);
        }

        // capture number of unique grants per year
        uniqueGrantsMadePerYear[taxYear] = uniqueGranteesPerYear[taxYear].size;

        // group grants made by grantee and then by year
        if (granteeFundingPerYear[granteeEin] !== undefined) {
          if (
            granteeFundingPerYear[granteeEin]["fundingPerYear"][taxYear] !==
            undefined
          ) {
            granteeFundingPerYear[granteeEin]["fundingPerYear"][taxYear] +=
              grantAmount;
          } else {
            granteeFundingPerYear[granteeEin]["fundingPerYear"][taxYear] =
              grantAmount;
          }
        } else {
          granteeFundingPerYear[granteeEin] = {
            orgNameOnGrant: i.org_name_on_grant,
            hasData:
              this.getFunderRecord(granteeEin) ||
              this.getNonprofitRecord(granteeEin)
                ? true
                : false,
            fundingPerYear: {
              [taxYear]: grantAmount,
            },
          };
        }
      });

      entityModalData = {
        ein: this.state.modalEin,
        funderRecord,
        nonprofitRecord,
        uniqueGrantsMadePerYear,
        granteeFundingPerYear,
        grantsReceived,
      };
    }

    return entityModalData;
  }

  getNonprofitData() {
    // Apply filters to the data, enrich with grant
    // information and clean up website URL
    return this.state.nonprofitData
      .filter(
        (nonprofit) =>
          nonprofit.revenue_timeline &&
          typeof nonprofit.revenue_timeline[this.state.taxYear] === "number" &&
          nonprofit.revenue_timeline[this.state.taxYear] > 0
      )
      .filter(
        (nonprofit) =>
          // If no issue area filters are set, then no need
          // to filter by issue area
          this.state.issueAreas.length === 0 ||
          this.state.issueAreas.some((issueArea) =>
            nonprofit.org_classes.includes(issueArea)
          )
      )
      .map((nonprofit) => {
        const grantsForSelectedYear = this.state.grantData.filter(
          (grant) =>
            // Make sure that both of these data types remain `number`
            grant.tax_year === this.state.taxYear &&
            grant.funder_ein === nonprofit.ein
        );
        nonprofit.grantsForSelectedYear = grantsForSelectedYear;
        nonprofit.website = getCleanedWebsite(nonprofit.website);
        return nonprofit;
      });
  }

  getFunderData() {
    // Apply filters to the data, and enrich with grant
    // information
    return (
      this.state.funderData
        .filter(
          (funder) =>
            funder.yearly_grant_totals &&
            typeof funder.yearly_grant_totals[this.state.taxYear] ===
              "number" &&
            funder.yearly_grant_totals[this.state.taxYear] > 0
        )
        .map((funder) => {
          const grantsForSelectedYear = this.state.grantData.filter(
            (grant) =>
              // Make sure that both of these data types remain `number`
              grant.tax_year === this.state.taxYear &&
              grant.funder_ein === funder.ein
          );

          funder.grantsForSelectedYear = grantsForSelectedYear;
          return funder;
        })
        // Only after the data has been enriched can we filter
        // for funders that fund a specific issue area
        .filter((funder) => {
          if (this.state.issueAreas.length === 0) {
            return true;
          }

          const granteeEinsForSelectedYear = funder.grantsForSelectedYear.map(
            (grant) => grant.grantee_ein
          );
          const granteesForSelectedYear = this.state.nonprofitData.filter(
            (nonprofit) => granteeEinsForSelectedYear.includes(nonprofit.ein)
          );

          return granteesForSelectedYear.some((grantee) =>
            this.state.issueAreas.some((issueArea) =>
              grantee.org_classes.includes(issueArea)
            )
          );
        })
    );
  }

  setTaxYear(taxYear) {
    this.setState({ taxYear });
  }

  setIssueAreas(issueAreas) {
    this.setState({ issueAreas });
  }

  setModalEin(ein) {
    this.setState({ modalEin: ein });
  }

  render() {
    const entityModalData = this.getEntityModalData();
    const nonprofitData = this.getNonprofitData();
    const funderData = this.getFunderData();

    const isFunder = Boolean(entityModalData.funderRecord);
    const isNonprofit = Boolean(entityModalData.nonprofitRecord);
    const isNonprofitFunder = isFunder && isNonprofit;

    return (
      <div className="mx-16 my-8 font-body antialiased">
        <header className="mb-2">
          <h1 className="text-5xl mb-4">
            Community Partnership Finder<sup className="pl-2">BETA</sup>
          </h1>
          <Filters
            geography={this.state.geography}
            taxYear={this.state.taxYear}
            issueAreas={this.state.issueAreas}
            unsetGeography={this.unsetGeography}
            setTaxYear={this.setTaxYear}
            setIssueAreas={this.setIssueAreas}
          />
        </header>
        <main className="flex flex-col gap-y-5 mt-4">
          <div className="flex flex-row justify-center gap-x-5">
            <MapPane
              geography={this.state.geography}
              taxYear={this.state.taxYear}
              funderData={funderData}
              nonprofitData={nonprofitData}
              indexData={this.state.indexData}
              setModalEin={this.setModalEin}
            />
          </div>
          <div className="flex flex-row justify-center gap-x-5">
            <NonprofitTablePane
              data={nonprofitData}
              taxYear={this.state.taxYear}
              setModalEin={this.setModalEin}
            />
            <FunderTablePane
              data={funderData}
              taxYear={this.state.taxYear}
              setModalEin={this.setModalEin}
            />
          </div>

          {/* Modals */}
          {this.state.geography === null && (
            <GeographySelectModal switchGeography={this.switchGeography} />
          )}

          {this.state.modalEin && (isNonprofitFunder || isNonprofit) && (
            <NonprofitEntityDetailModal
              {...entityModalData}
              setModalEin={this.setModalEin}
            />
          )}

          {this.state.modalEin && isFunder && !isNonprofitFunder && (
            <FunderEntityDetailModal
              {...entityModalData}
              setModalEin={this.setModalEin}
            />
          )}

          {this.context.infoContent && (
            <InfoModal
              elementName={this.context.infoElementName}
              content={this.context.infoContent}
              onClose={() => {
                this.context.setInfoContent(null);
                this.context.setInfoElementName(null);
              }}
            />
          )}
        </main>
        <footer className="w-full mt-4">
          <div className="max-w-6xl ml-auto mr-auto p-4 pt-6 pb-22">
            <div className="text-sm text-center pb-4">
              Copyright © {new Date().getFullYear()} Community Partnership
              Finder, All rights reserved.
            </div>
            <div className="text-sm flex justify-evenly">
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="mailto:hello@communitypartnershipfinder.org"
              >
                Contact Us
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
App.contextType = InfoModalContext;
